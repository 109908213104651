import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';
import { getState } from 'store/store';

// this header are used by our request tracker (reblaze) so then we can construct the user's journey
export const addUserInfoForRequestTracker = (config, restClientInstance) => {
	if (!config.externalRequest) {
		restClientInstance._axios.interceptors.request.use((config) => setHeader(config));
	}
};

const setHeader = async (config) => {
	const user = loggedUSerSelector(getState());
	const { dealership_id: dealershipId, id } = user || {};

	if (id) {
		config.headers['x-rbz-user-id'] = id;
		config.headers['x-rbz-dealership-id'] = dealershipId;
	}
	return config;
};
