import { authenticatedRestClient } from 'helpers/restclient';
export default class ActivitiesService {
	static instance = new ActivitiesService();

	_endpoints = {
		getAllActivities: '/api/activities',
		deleteAllActivities: '/api/activities/delete_all',
		readAllActivities: '/api/activities/read',
		deleteActivity: (activityId) => `/api/activities/${activityId}`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	getActivities = () => this.restClient.get(this._endpoints.getAllActivities, {});

	deleteActivity = (user, activityId) => this.restClient.delete(this._endpoints.deleteActivity(activityId));

	deleteAllActivities = () => this.restClient.post(this._endpoints.deleteAllActivities, {});

	readAllActivities = () => this.restClient.post(this._endpoints.readAllActivities, {});
}
