export const PRICING_DETAILS_FILTERS = {
	binSet: 'bin_set',
	noBinSet: 'no_bin_set',
	reserveSet: 'reserve_set',
	noReserveSet: 'no_reserve_set',
};

export const DEFAULT_SORTING_OPTION = {
	value: { order_by_spread: true, direction: 'asc' },
	text: 'Spread (smallest)',
	isSelected: true,
};

export const SORTING_OPTIONS = [
	DEFAULT_SORTING_OPTION,
	{ value: { order_by_spread: true, direction: 'desc' }, text: 'Spread (largest)', isSelected: false },
	{ value: { order_by_expires_at: true }, text: 'Expiring (soonest)', isSelected: false },
	{ value: { order_by_activity: true, direction: 'desc' }, text: 'Activity (most recent)', isSelected: false },
	{ value: { order_by_activity: true, direction: 'asc' }, text: 'Activity (oldest)', isSelected: false },
	{ value: { order_by_buyer_name: true, direction: 'asc' }, text: 'Buyer A-Z', isSelected: false },
	{ value: { order_by_buyer_name: true, direction: 'desc' }, text: 'Buyer Z-A', isSelected: false },
	{ value: { order_by_seller_name: true, direction: 'asc' }, text: 'Seller A-Z', isSelected: false },
	{ value: { order_by_seller_name: true, direction: 'desc' }, text: 'Seller Z-A', isSelected: false },
	{ value: { order_by_seller_close_rate: true }, text: 'Seller close rate (highest)', isSelected: false },
];

export const initialState = {
	metadata: null,
	loading: false,
	success: undefined,
	data: null,
	adminUsers: {
		data: [],
		loading: false,
		success: undefined,
		error: null,
		metadata: null,
		formData: { name: null },
	},
	filters: {
		keywords: '',
		my_turn: null,
		deal_working_statuses: null,
		sale_type: 'all',
		deal_worker: undefined,
		[PRICING_DETAILS_FILTERS.binSet]: false,
		[PRICING_DETAILS_FILTERS.noBinSet]: false,
		[PRICING_DETAILS_FILTERS.reserveSet]: false,
		[PRICING_DETAILS_FILTERS.noReserveSet]: false,
	},
	sorting: DEFAULT_SORTING_OPTION.value,
};
