import { createSlice } from '@reduxjs/toolkit';
import { MARKETPLACE_TAB } from 'constants/marketplace';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';
import { loadFiltersForTab, searchPreview } from './filtersThunks';
import { getConfigParamsFromQuery, getSanitizedFilterParams } from './helpers';
import {
	getFilter,
	getHotfilter,
	handleCheckboxChange,
	handleFilterChange,
	removeFilter,
	resetFilterToDefaultValue,
	removeFilterFromAppliedList,
} from './helpers/actionsHelpers';

export const initialSearchListFilters = {
	isFilterModalOpened: false,
	filterSections: [],
	hotfilters: [],
	sortingOptions: [],
	tabs: [],
	areFiltersInitialized: false,
	areFiltersLoading: false,
	currentSearch: {
		// Params contain all filters selection state in the same format that BE need it,
		params: {
			page: 1,
			// rest of applied filters params
		},
		defaultFilterParams: {},
		paramsVersion: 0,
	},
	draft: {
		paramsFilters: {}, // here we store filter state for components that use `apply filter` button, so once user confirms we copy this state to params.
		isPreviewLoading: false,
		changedFilters: [],
		vehiclesThatMatchFilters: null,
	},
	appliedFilters: {
		count: 0,
		filters: [], // contains list of applied filters, used on activeFilters block
	},
};

export const initialState = {
	searchLists: {
		[MARKETPLACE_TAB.marketplace]: initialSearchListFilters,
		[MARKETPLACE_TAB.offers]: initialSearchListFilters,
		[MARKETPLACE_TAB.watchlist]: initialSearchListFilters,
		[MARKETPLACE_TAB.hidden]: initialSearchListFilters,
		[LIST_KEYS.auction]: initialSearchListFilters,
		[LIST_KEYS.offers]: initialSearchListFilters,
		[LIST_KEYS.hidden]: initialSearchListFilters,
		[LIST_KEYS.watchlist]: initialSearchListFilters,
	},
	configs: {}, // here we saved generic url options like, if its CTN or private marketplace.
	tabs: [],
	areConfigsLoaded: false,
};

const getSearchList = ({ state, searchListKey }) => {
	return state.searchLists[searchListKey];
};

export const configurableSearchFilters = createSlice({
	name: 'searchFilters',
	initialState,
	reducers: {
		toggleFiltersModal: (state, { payload }) => {
			const { isOpened, listKey, totalResultsForAppliedFilters } = payload;
			state.searchLists[listKey].isFilterModalOpened = isOpened;
			if (isOpened) {
				// set applied filters to draft.paramsFilters so user can build from applied filters
				state.searchLists[listKey].draft.paramsFilters = {
					...state.searchLists[listKey].currentSearch.params,
				};

				// as draft equals applied filter on modal open
				state.searchLists[listKey].draft.changedFilters = [
					...state.searchLists[listKey].appliedFilters.filters,
				];
				state.searchLists[listKey].draft.vehiclesThatMatchFilters = totalResultsForAppliedFilters;
			}
		},
		applyDraftFilters: (state, { payload }) => {
			const { listKey } = payload;
			state.searchLists[listKey].currentSearch.params = {
				...state.searchLists[listKey].draft.paramsFilters,
			};

			// here we can improve it and check deep equality before apply it.
			const currentSearch = state.searchLists[listKey].currentSearch;
			currentSearch.params.page = 1;
			currentSearch.paramsVersion++;
			// close modal after apply
			state.searchLists[listKey].isFilterModalOpened = false;
		},
		// Filter changes with auto apply
		onCheckboxChange: (state, { payload }) => {
			const { listKey, parentOptionValue } = payload;
			const { didFilterChange } = handleCheckboxChange({
				checkboxChangePayload: payload,
				currentFilterParamsState: state.searchLists[listKey].currentSearch.params,
				parentOptionValue,
			});
			if (didFilterChange) {
				const currentSearch = state.searchLists[listKey].currentSearch;
				currentSearch.params.page = 1;
				currentSearch.paramsVersion++;
			}
		},
		onFilterChange: (state, { payload }) => {
			const { listKey, filterState } = payload;
			const { didFilterChange } = handleFilterChange({
				changedFilterState: filterState,
				currentFilterParamsState: state.searchLists[listKey].currentSearch.params,
			});
			if (didFilterChange) {
				const currentSearch = state.searchLists[listKey].currentSearch;
				currentSearch.params.page = 1;
				currentSearch.paramsVersion++;
			}
		},
		removeAppliedFilter: (state, { payload }) => {
			const { listKey, key, value, type } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			removeFilter({
				payload: {
					key,
					value,
					type,
				},
				currentFilterParamsState: state.searchLists[listKey].currentSearch.params,
				defaultFilterParams: currentSearch.defaultFilterParams,
			});
			currentSearch.params.page = 1;
			currentSearch.paramsVersion++;
		},
		// Filter changes pending to apply
		draftCheckboxChange: (state, { payload }) => {
			const { listKey, parentOptionValue } = payload;
			handleCheckboxChange({
				checkboxChangePayload: payload,
				currentFilterParamsState: state.searchLists[listKey].draft.paramsFilters,
				parentOptionValue,
			});
		},
		draftFilterChange: (state, { payload }) => {
			const { listKey, filterState } = payload;
			handleFilterChange({
				changedFilterState: filterState,
				currentFilterParamsState: state.searchLists[listKey].draft.paramsFilters,
			});
		},
		removeDraftFilter: (state, { payload }) => {
			const { listKey, key, value, type } = payload;
			const searchList = state.searchLists[listKey];
			const currentSearch = searchList.currentSearch;
			removeFilter({
				payload: {
					key,
					value,
					type,
				},
				currentFilterParamsState: state.searchLists[listKey].draft.paramsFilters,
				defaultFilterParams: currentSearch.defaultFilterParams,
			});
			searchList.draft.changedFilters = removeFilterFromAppliedList({
				filterToRemove: { key, value, type },
				appliedFilters: searchList.draft.changedFilters,
			});
		},

		sectionToggle: (state, { payload }) => {
			const { listKey, sectionKey, collapsing } = payload;
			const sectionToModify = state.searchLists[listKey]?.filterSections.find(({ key }) => key === sectionKey);
			if (sectionToModify) {
				sectionToModify.isCollapsed = collapsing;
			}
		},
		updateConfigs: (state, { payload }) => {
			const { configs, unifiedMarketplace } = payload;
			if (configs) state.configs = getConfigParamsFromQuery(configs, unifiedMarketplace);
			state.areConfigsLoaded = true;
		},
		removeConfigs: (state) => {
			state.configs = {};
		},
		updateAppliedFilters: (state, { payload }) => {
			const { appliedFilters, listKey } = payload;
			state.searchLists[listKey].appliedFilters = appliedFilters;
		},
		applySavedSearch: (state, { payload }) => {
			const { listKey, savedSearch, doNotReloadList } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			currentSearch.params = {
				page: currentSearch.params.page,
				...savedSearch.params,
				saved_search_id: savedSearch.id,
			};
			if (!doNotReloadList) {
				currentSearch.params.page = 1;
				currentSearch.paramsVersion++;
			}
		},
		updateFiltersFromQueryParam: (state, { payload }) => {
			const { listKey, searchParams } = payload;

			const currentSearch = state.searchLists[listKey].currentSearch;
			currentSearch.params = getSanitizedFilterParams(
				searchParams,
				currentSearch.defaultFilterParams,
				state.searchLists[listKey].filterSections,
			);
			currentSearch.paramsVersion++;
		},
		changeSorting: (state, { payload }) => {
			const { searchListKey: listKey, order_by: orderBy, direction } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			const oldValues = {
				orderBy: currentSearch.params.order_by,
				direction: currentSearch.params.direction,
			};

			if (oldValues.orderBy !== orderBy || oldValues.direction !== direction) {
				currentSearch.params.order_by = orderBy;
				currentSearch.params.direction = direction;
				currentSearch.params.page = 1;
				currentSearch.paramsVersion++;
			}
		},
		changeOrderByExtension: (state, { payload }) => {
			const { orderByExtension, listKey } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;

			if (currentSearch.params.order_by_extension !== orderByExtension) {
				currentSearch.params.order_by_extension = orderByExtension;
				currentSearch.params.page = 1;
				currentSearch.paramsVersion++;
			}
		},
		pageChange: (state, { payload }) => {
			const { listKey, page } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			currentSearch.params.page = page;
			currentSearch.paramsVersion++;
		},
		clearAllFilters: (state, { payload }) => {
			const { listKey } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;

			currentSearch.params = { ...currentSearch.defaultFilterParams, page: 1 };
			// Clear saved search
			currentSearch.id = null;
			currentSearch.name = null;
			currentSearch.paramsVersion++;
		},
		collapseFilterOption: (state, { payload }) => {
			const { searchListKey, sectionKey, filterKey, value: valueToCollapse } = payload;
			const filter = getFilter(state, searchListKey, sectionKey, filterKey);
			const option = filter.options.find(({ value }) => value === valueToCollapse);
			option.is_expanded = false;
		},
		changeFilterOptions: (state, { payload }) => {
			// sets opened options
			const { searchListKey, sectionKey, filterKey, value } = payload;
			const filter = getFilter(state, searchListKey, sectionKey, filterKey);
			for (const option of filter.options) {
				// value could be string if we change Accordion to use type='single'
				option.is_expanded = typeof value === 'string' ? option.value === value : value?.includes(option.value);
			}
		},
		collapseHotFilterOption: (state, { payload }) => {
			const { searchListKey, filterKey, value: valueToCollapse } = payload;
			const filter = getHotfilter(state, searchListKey, filterKey);
			const option = filter.options.find(({ value }) => value === valueToCollapse);
			option.is_expanded = false;
		},
		changeHotFilterOptions: (state, { payload }) => {
			const { searchListKey, filterKey, value } = payload;
			const filter = getHotfilter(state, searchListKey, filterKey);
			for (const option of filter.options) {
				option.is_expanded = typeof value === 'string' ? option.value === value : value?.includes(option.value);
			}
		},
		clearFilter: (state, action) => {
			const { payload } = action;
			const { listKey, filterKey, filterKeys } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			// Some filters like range have multiple keys, so we need to clear all of them
			const completed = resetFilterToDefaultValue({ currentSearch, filterKey, filterKeys });
			action.completed = completed;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadFiltersForTab.pending, (state, { meta }) => {
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.areFiltersInitialized = false;
			searchList.areFiltersLoading = true;
		});
		builder.addCase(loadFiltersForTab.fulfilled, (state, { payload, meta }) => {
			const { sections, hotfilters, params, defaultParams, sortingOptions, tabs } = payload;
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.areFiltersInitialized = true;
			searchList.areFiltersLoading = false;
			searchList.filterSections = sections;
			searchList.hotfilters = hotfilters;
			searchList.sortingOptions = sortingOptions;
			searchList.currentSearch.params = { page: 1, ...params };
			searchList.currentSearch.defaultFilterParams = { page: 1, ...defaultParams };
			if (tabs?.length) state.tabs = tabs;
		});
		builder.addCase(loadFiltersForTab.rejected, (state, { meta }) => {
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.areFiltersLoading = false;
			searchList.areFiltersInitialized = true; // list is still usable but without filtering
		});
		// preview actions
		builder.addCase(searchPreview.pending, (state, { meta }) => {
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.draft.isPreviewLoading = true;
		});
		builder.addCase(searchPreview.fulfilled, (state, { payload, meta }) => {
			const { totalMatches, changedFilters } = payload;
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.draft.isPreviewLoading = false;
			if (changedFilters) searchList.draft.changedFilters = changedFilters;
			searchList.draft.vehiclesThatMatchFilters = totalMatches;
		});
		builder.addCase(searchPreview.rejected, (state, { meta }) => {
			const { aborted } = meta;
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			if (!aborted) {
				searchList.draft.isPreviewLoading = false;
				searchList.draft.vehiclesThatMatchFilters = null;
			}
		});
	},
});

export const {
	toggleFiltersModal,
	applyDraftFilters,
	sectionToggle,
	updateConfigs,
	removeConfigs,
	updateAppliedFilters,
	onCheckboxChange,
	onFilterChange,
	changeSorting,
	changeOrderByExtension,
	pageChange,
	applySavedSearch,
	removeAppliedFilter,
	updateFiltersFromQueryParam,
	clearAllFilters,
	clearFilter,
	// draft actions
	draftCheckboxChange,
	draftFilterChange,
	removeDraftFilter,
	collapseFilterOption,
	changeFilterOptions,
	collapseHotFilterOption,
	changeHotFilterOptions,
} = configurableSearchFilters.actions;
export default configurableSearchFilters.reducer;
