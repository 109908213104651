import { authenticatedRestClient } from 'helpers/restclient';
import { buildParamsURL } from 'helpers/search';

export default class BuysideSearchService {
	static instance = new BuysideSearchService();

	_endpoints = {
		searchVehicles: '/api/search',
		searchVehiclesUnifiedMarketplace: '/api/v4/search',
		searchPreview: (isAuction) => `/api/${isAuction ? 'timed_sales' : 'search'}/preview`,
		searchPreviewUnifiedMarketplace: `/api/v4/search/preview`,
		searchVehiclesClosedTradeNetwork: '/api/closed_trade_network/search',
		offers: '/api/vehicles/offers',
		offersClosedTradeNetwork: '/api/closed_trade_network/vehicles/offers',
		getSavedSearches: '/api/search_filters',
		getSavedSearch: (savedSearchId) => `/api/search_filters/${savedSearchId}`,
		getSavedSearchNew: '/api/configurable_search/saved_searches',
		getFilters: '/api/configurable_search',
		getDefaultFilters: '/api/search/filters',
		getDefaultFiltersClosedTradeNetwork: '/api/closed_trade_network/search/filters',
		deleteSavedSearch: (savedSearchId) => `/api/search_filters/${savedSearchId}`,
		updateSavedSearch: (savedSearchId) => `/api/search_filters/${savedSearchId}`,
		createSavedSearch: `/api/search_filters/`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	searchVehicles = (params, closedTradeNetwork, cancelToken, unifiedMarketplace, endpoint) => {
		let baseURL;
		if (endpoint) {
			baseURL = endpoint;
		} else if (closedTradeNetwork) {
			baseURL = this._endpoints.searchVehiclesClosedTradeNetwork;
		} else if (unifiedMarketplace) {
			baseURL = this._endpoints.searchVehiclesUnifiedMarketplace;
		} else {
			baseURL = this._endpoints.searchVehicles;
		}
		return this.restClient.get(`${baseURL}?${buildParamsURL(params)}`, null, {}, cancelToken);
	};

	searchPreview = (params, isAuction, cancelToken, unifiedMarketplace) => {
		let baseURL;
		if (unifiedMarketplace) {
			baseURL = this._endpoints.searchPreviewUnifiedMarketplace;
		} else {
			baseURL = this._endpoints.searchPreview(isAuction);
		}
		return this.restClient.get(`${baseURL}?${buildParamsURL(params)}`, null, {}, cancelToken);
	};

	getOffers = (params, closedTradeNetwork, cancelToken, unifiedMarketplace) => {
		let baseURL = unifiedMarketplace ? this._endpoints.searchVehiclesUnifiedMarketplace : this._endpoints.offers;

		const getOfferParams = {
			...params,
			source_tab: 'offers',
			...(closedTradeNetwork && { context: 'closed_trade_network' }),
			...(unifiedMarketplace && { context: 'unified_search' }),
		};

		return this.restClient.get(`${baseURL}?${buildParamsURL(getOfferParams)}`, null, {}, cancelToken);
	};

	getSavedSearches = (params, cancelToken) => {
		let baseURL = this._endpoints.getSavedSearches;
		return this.restClient.get(`${baseURL}?${buildParamsURL(params)}`, null, {}, cancelToken);
	};
	getSavedSearchesNew = ({ context, source_tab }, cancelToken) => {
		let endpointUrl = this._endpoints.getSavedSearchNew;

		return this.restClient.get(`${endpointUrl}?${buildParamsURL({ context, source_tab })}`, null, {}, cancelToken);
	};

	getSavedSearch = async (savedSearchId, cancelToken) => {
		if (savedSearchId) {
			let baseURL = this._endpoints.getSavedSearch(savedSearchId);
			const savedSearch = await this.restClient.get(baseURL, null, {}, cancelToken);
			const searchFilters = savedSearch?.data?.data?.search_filters;
			if (searchFilters?.length) return searchFilters[0];
		}
		return null;
	};

	getFilters = async ({ context, sourceTab, cancelToken }) => {
		const endpoint = this._endpoints.getFilters;
		return this.restClient.get(endpoint, { context, source_tab: sourceTab }, {}, cancelToken);
	};

	getDefaultFilters = async (closedTradeNetwork, cancelToken) => {
		let baseURL =
			!closedTradeNetwork ?
				this._endpoints.getDefaultFilters
			:	this._endpoints.getDefaultFiltersClosedTradeNetwork;
		return this.restClient.get(baseURL, null, {}, cancelToken);
	};

	deleteSavedSearch = async (savedSearchId, cancelTOken) => {
		return this.restClient.delete(this._endpoints.deleteSavedSearch(savedSearchId), {}, cancelTOken);
	};

	updateSavedSearch = async (savedSearchId, changes, cancelToken) => {
		return this.restClient.put(this._endpoints.updateSavedSearch(savedSearchId), changes, {}, cancelToken);
	};

	createSavedSearch = async (savedSearch, cancelToken) => {
		return this.restClient.post(this._endpoints.createSavedSearch, savedSearch, {}, cancelToken);
	};
}
