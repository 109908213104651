import { buildParamsURL } from 'helpers/search';

export const CONDITION_REPORT_HEADERS = { 'X-Configuration-Definition-ID': '1' };

const getConditionReportUrl = (id, vdp, reportid) => {
	const baseURL = vdp ? '/api/condition_reports' : '/api/condition_report';
	if (!vdp) {
		return `${baseURL}/${reportid}`;
	} else {
		return id ? `${baseURL}/${id}?request_images=true` : null;
	}
};

const getConditionReportPageUrl = (token, partnerName, disablePartnerScript) => {
	const params = buildParamsURL({ partner_name: partnerName, disable_partner_script: disablePartnerScript });
	return `/condition-report/${token}${params ? `?${params}` : ''}`;
};

export { getConditionReportUrl, getConditionReportPageUrl };
