import { authenticatedRestClient } from 'helpers/restclient';

export default class TransportationServices {
	static instance = new TransportationServices();

	#endpoints = {
		jobs: '/api/transportation/jobs',
	};

	constructor() {
		this.authenticatedRestClient = authenticatedRestClient;
	}

	fetchTransportationJobs = (payload) => {
		const endpoint = this.#endpoints.jobs;
		return this.authenticatedRestClient.get(endpoint, payload);
	};

	removeTransport = (orderId) => {
		const endpoint = `${this.#endpoints.jobs}/${orderId}`;
		return this.authenticatedRestClient.delete(endpoint);
	};
}
