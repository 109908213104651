/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import BuyerOffersService from 'services/marketplace/buyside/offers.service';

export const getVehicleOfferState = createAsyncThunk('getVehicleOfferState', async ({ vehicle }, thunkAPI) => {
	const { rejectWithValue } = thunkAPI;

	const { data } = await BuyerOffersService.instance.offerRefresh({
		vehicles: [vehicle],
		isVdp: true,
		reason: 'first_load',
		forceRefresh: true,
	});

	if (data?.data?.vehicles?.length) return { offerStatus: data.data.vehicles[0] };

	return rejectWithValue({ errorMessage: 'offer status could not be loaded' });
});
