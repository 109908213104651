import { encrypt } from 'helpers/crypto';
import { publicEnvConfig, privateEnvConfig } from 'helpers/env';

const getLoginURL = async (ctx, required) => {
	const baseUrl = publicEnvConfig.BASE_URL;
	const url = encrypt(
		baseUrl,
		privateEnvConfig.CRYPTO_ALGORITHM,
		privateEnvConfig.CRYPTO_KEY,
		privateEnvConfig.CRYPTO_IV,
	);

	if (required) {
		return `${baseUrl}/sso_frontend?sso_redirect=${encodeURIComponent(url)}`;
	}

	return `${baseUrl}/sso_frontend_optional/${encodeURIComponent(url)}`;
};

export default getLoginURL;
