/* eslint-disable camelcase */
import { authenticatedRestClient, withCookiesRestClient } from 'helpers/restclient';
import { publicEnvConfig } from 'helpers/env';

export default class UserSessionService {
	static instance = new UserSessionService();

	_endpoints = {
		logout: '/api/users/sessions',
		groupAccounts: '/api/sellers/masters/users',
		changeAccount: '/api/masters/sessions',
		reloadUser: '/users/cookie',
		getUserNavigationInfo: '/api/users/navigation',
		signIn: '/api/users/sessions',
		refreshAccessToken: '/api/users/sessions/refresh',
		oktaProfiles: '/api/okta_profiles',
	};

	constructor() {
		this.authenticatedRestClient = authenticatedRestClient;
		this.withCookiesRestClient = withCookiesRestClient;
	}

	logout = () => this.authenticatedRestClient.delete(`${this._endpoints.logout}`, {});

	getGroupAccounts = async () => await this.authenticatedRestClient.get(this._endpoints.groupAccounts, {});

	changeAccount = async (id) => {
		const { data } = await this.authenticatedRestClient.post(
			`${publicEnvConfig.RELATIVE_BASE_URL}${this._endpoints.changeAccount}`,
			{ user_id: id },
		);
		return data?.redirect_path;
	};

	reloadUser = async () => {
		await this.authenticatedRestClient.post(
			`${publicEnvConfig.RELATIVE_BASE_URL}${this._endpoints.reloadUser}`,
			{},
		);
	};

	getUserNavigationInfo = async (accessToken) =>
		await this.withCookiesRestClient.get(
			`${publicEnvConfig.RESTURL_BASE}${this._endpoints.getUserNavigationInfo}`,
			undefined,
			{
				Authorization: `Token token=${accessToken}`,
			},
		);

	signIn = (email, password, utmParams, oktaCode) => {
		const requestBody = oktaCode ? {} : { user: { email, password } };
		const requestHeaders = oktaCode ? { 'Okta-Access-Token': oktaCode } : {};
		const queryParams = utmParams ? `&${utmParams}` : '';
		return this.withCookiesRestClient.post(`${this._endpoints.signIn}?${queryParams}`, requestBody, requestHeaders);
	};

	refreshAccessToken = (refreshToken, headers) => {
		return this.withCookiesRestClient.post(
			`${publicEnvConfig.RESTURL_BASE}${this._endpoints.refreshAccessToken}`,
			{},
			{
				Cookie: `;refresh_token=${refreshToken}`,
				...headers,
			},
		);
	};

	refreshAccessTokenLegacy = (headers) => {
		return this.withCookiesRestClient.post(
			`${publicEnvConfig.RESTURL_BASE}${'/api/users/sessions/web_jwt'}`,
			{},
			headers,
		);
	};

	checkOktaProfile = ({ username, password }) => {
		return this.withCookiesRestClient.post(`${publicEnvConfig.RESTURL_BASE}${this._endpoints.oktaProfiles}`, {
			username,
			password,
		});
	};

	confirmOktaProfileLink = ({ invitationToken }) => {
		return this.withCookiesRestClient.put(
			`${publicEnvConfig.RESTURL_BASE}${this._endpoints.oktaProfiles}/${invitationToken}`,
			{},
		);
	};
}
