import { authenticatedRestClient } from 'helpers/restclient';

export default class CheckoutService {
	static instance = new CheckoutService();

	_endpoints = {
		getCheckoutItems: `/api/checkout`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	getCheckoutItems = (cancelToken) => {
		const endpoint = this._endpoints.getCheckoutItems;
		return this.restClient.get(endpoint, {}, {}, cancelToken);
	};
}
