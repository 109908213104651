/* istanbul ignore file */
import { createWrapper } from 'next-redux-wrapper';
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { setStore } from 'store/store';
import { publicEnvConfig } from 'helpers/env';

const isDevtoolTurnedOnViaConfig = () => {
	if (typeof window !== 'undefined') {
		return localStorage?.getItem('enable-dev-tools');
	}
};

export const makeStore = (props) => {
	const initialState = props?.initialState || props;
	const justCreatedStore = configureStore({
		reducer: rootReducer,
		preloadedState: initialState,
		devTools: publicEnvConfig.RELEASE_STAGE !== 'production' || isDevtoolTurnedOnViaConfig(),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}),
	});
	setStore(justCreatedStore);
	return justCreatedStore;
};

export const wrapper = createWrapper(() => makeStore(), {
	serializeState: (state) => JSON.stringify(state),
	deserializeState: (state) => JSON.parse(state),
});
