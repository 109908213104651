/* eslint-disable camelcase */
import { toast } from '@Backlot-Cars/archie';
import { createSlice } from '@reduxjs/toolkit';
import { isMarketplace } from 'components/card/Buyers/Helpers';
import { BID_SALE_AUCTION_STATUS, BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { HYDRATE } from 'next-redux-wrapper';
import { initialState as searchInitialState } from 'reducers/search';
import {
	setOvertimeCompleted as setOvertimeCompletedHelper,
	updateOfferStatus,
} from 'store/helpers/vehicleOfferUpdates';
import {
	calculatePages,
	calculatePaginationAfterAddition,
	getListKeysForWatchlistAndHiddenActions,
	insertVehicleIntoIdslist,
	removeFromVehicleIds,
} from 'store/helpers/watchlist';
import { closeCampaign, loadFeaturedVehicleCampaign } from 'store/slices/buyerVehicles/thunks/featuredVehicles';
import { loadVehicles, loadVehiclesMarketplace, setVehicleAsHidden, unHideVehicle } from './buyerVehiclesThunks';
import {
	addVehicleToListKeyAndUpdateEntities,
	getKeyFromSourceTab,
	getNotSelectedKeys,
	LIST_KEYS,
	removeVehicleFromListKeyAndUpdateEntities,
	vehiclesAdapter,
} from './helpers/vehicles';

export const initialState = vehiclesAdapter.getInitialState({
	...searchInitialState,
	focussedTab: BID_SALE_SOURCE_TAB.auction,
	focussedTabsSincePageLoads: [BID_SALE_SOURCE_TAB.auction],
});

export const buyerTimedSaleVehiclesSlice = createSlice({
	name: 'buyerVehicles',
	initialState,
	reducers: {
		startBid: (state, { payload }) => {
			const { id } = payload;
			const entity = state.entities[id];

			const currentAuctionStatus = entity?.bid_sale_auction_status;
			let newAuctionStatus = currentAuctionStatus;
			if (!currentAuctionStatus || currentAuctionStatus === BID_SALE_AUCTION_STATUS.pending) {
				newAuctionStatus = BID_SALE_AUCTION_STATUS.live;
			}

			vehiclesAdapter.updateOne(state, { id, changes: { bid_sale_auction_status: newAuctionStatus } });
		},
		moveVehiclesToLiveOnAuctionStart: (state, { payload: { bid_sale_auction_id } }) => {
			Object.keys(state.entities).forEach((id) => {
				const vehicle = state.entities[id];
				if (vehicle) {
					const currentAuctionStatus = state.entities[id].bid_sale_auction_status;
					const isAuctionVehicle = !isMarketplace(vehicle);
					const isVehicleOnCurrentAuction =
						bid_sale_auction_id && vehicle.bid_sale_auction_id === bid_sale_auction_id;
					if (
						isAuctionVehicle &&
						isVehicleOnCurrentAuction &&
						(!currentAuctionStatus || currentAuctionStatus === BID_SALE_AUCTION_STATUS.pending)
					) {
						vehiclesAdapter.updateOne(state, {
							id,
							changes: { bid_sale_auction_status: BID_SALE_AUCTION_STATUS.live },
						});
					}
				}
			});
		},
		endBid: (state) => {
			Object.values(state.entities).forEach((entity) => {
				const { id, bid_sale_auction_status } = entity;
				// eslint-disable-next-line camelcase
				if (bid_sale_auction_status === 'overtime') {
					vehiclesAdapter.updateOne(state, { id, changes: { auctionEnded: true } });
				}
			});
		},
		updateBid: (state, { payload: { id, changes: encodedChanges, loggedUser } }) => {
			const changes = updateOfferStatus({
				currentOfferState: state.entities[id],
				updatedVehicleId: id,
				updatedOfferState: encodedChanges,
				loggedUser,
			});

			if (changes) vehiclesAdapter.updateOne(state, { id, changes });
		},
		setOvertimeCompleted: (state, { payload: { id } }) => {
			const changes = setOvertimeCompletedHelper({ currentOfferState: state.entities[id] });
			vehiclesAdapter.updateOne(state, {
				id,
				changes,
			});
		},
		removeVehicleFromCurrentPage: (state, { payload }) => {
			const { vehicleId, listKey } = payload;
			removeVehicleFromListKeyAndUpdateEntities({ state, vehicleId, listKey });
		},
		addVehicleToOfferList: (state, { payload: { vehicle, searchListKey } }) => {
			const { totalElements, itemsPerPage, vehicleIds } = state.searchLists[searchListKey];
			state.searchLists[searchListKey].totalElements = totalElements + 1;
			state.searchLists[searchListKey].pageCount = calculatePages(
				state.searchLists[searchListKey].totalElements,
				itemsPerPage,
			);

			if (vehicleIds.length < itemsPerPage) {
				// once tbd_126623_unified_marketplace_response we can delete next line.
				vehiclesAdapter.upsertOne(state, { id: vehicle.id, ...vehicle });
				if (!vehicleIds.includes(vehicle.id)) vehicleIds.push(vehicle.id);
			}
		},
		// modifying vehicle list
		// IMPORTANT CONTEXT: we know that list ir ordered by bid_sale_id desc so we can know where to add or remove elements based on that
		addToWatchlist: (state, { payload }) => {
			const { vehicle, listKey } = payload;
			const listKeyForWatchlist = listKey || LIST_KEYS.watchlist;
			const { entities } = state;
			const currentPage = state.searchLists[listKeyForWatchlist].currentSearch.params.page;
			const { vehicleIds, itemsPerPage } = state.searchLists[listKeyForWatchlist];

			// we only insert into the list if it's on first page or the list is not full
			if (state.searchLists[listKeyForWatchlist].totalElements < itemsPerPage || currentPage === 1) {
				insertVehicleIntoIdslist(entities, vehicleIds, vehicle, itemsPerPage);
			}

			// it should exist on entities, since user is clicking some card button, this is defensive code
			state.entities[vehicle.id] ?
				(state.entities[vehicle.id].watching = true)
			:	(state.entities[vehicle.id] = { ...vehicle, watching: true });

			const { totalElements, pageCount } = calculatePaginationAfterAddition(
				state.searchLists[listKeyForWatchlist].totalElements,
				itemsPerPage,
			);
			state.searchLists[listKeyForWatchlist].totalElements = totalElements;
			state.searchLists[listKeyForWatchlist].pageCount = pageCount;
			if (state.searchLists[listKeyForWatchlist].totalElements === 1) {
				state.searchLists[listKeyForWatchlist].currentSearch.params.page = 1;
			}
		},
		removeFromWatchlist: (state, { payload }) => {
			const { vehicle, doNotModifyCurrentPage, watchlistListKey } = payload;
			const { entities } = state;

			// remove from list
			if (!doNotModifyCurrentPage) {
				const { vehicleIds } = removeFromVehicleIds({
					vehicleIds: state.searchLists[watchlistListKey].vehicleIds,
					vehicleIdToRemove: vehicle.id,
					totalElements: state.searchLists[watchlistListKey].totalElements,
					itemsPerPage: state.searchLists[watchlistListKey].itemsPerPage,
				});
				state.searchLists[watchlistListKey].vehicleIds = vehicleIds;
			}

			state.searchLists[watchlistListKey].totalElements -= 1;
			state.searchLists[watchlistListKey].pageCount = calculatePages(
				state.searchLists[watchlistListKey].totalElements,
				state.searchLists[watchlistListKey].itemsPerPage,
			);

			if (entities[vehicle.id]) state.entities[vehicle.id].watching = false;
		},
		setFocussedTab: (state, { payload }) => {
			const { focussedTab } = payload;
			state.focussedTab = focussedTab;
			if (!state.focussedTabsSincePageLoads.includes(focussedTab)) {
				state.focussedTabsSincePageLoads.push(focussedTab);
			}
		},
		// Remove when deleting tbd_187929_unified_marketplace_react mixed experience
		setUnifiedMarketplaceSaleType: (state, { payload }) => {
			const { saleType } = payload;
			state.saleType = saleType;
		},
		clearFeaturedVehicleCampaign: (state, { payload }) => {
			const { searchListKey } = payload;
			state.searchLists[searchListKey].featured = initialState.searchLists[searchListKey].featured;
		},
		clearAllVehiclesList: (state) => {
			Object.keys(state.searchLists).forEach((key) => {
				state.searchLists[key].vehicleIds = [];
			});
			state.entities = {};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			// just merge the data on hydrate
			return {
				...state,
				focussedTab: action.payload.buyerVehicles?.focussedTab,
				saleType: action.payload.buyerVehicles?.saleType,
			};
		});
		builder.addCase(loadVehicles.pending, (state, action) => {
			const key = getKeyFromSourceTab(action.meta.arg.sourceTab);
			state.searchLists[key].isLoading = true;
			state.searchLists[key].success = undefined;
		});
		builder.addCase(loadVehicles.fulfilled, (state, { payload, meta }) => {
			let listKey = getKeyFromSourceTab(meta.arg.sourceTab);
			const {
				data: { vehicles },
				metadata,
			} = payload;

			addVehicleToListKeyAndUpdateEntities({ newVehicleList: vehicles, state, listKey, shouldDecode: true });
			state.searchLists[listKey].pageCount = metadata.page_count;
			state.searchLists[listKey].totalElements = metadata.total_count;
			state.searchLists[listKey].currentSearch.params.page = Number(metadata.page);
			state.searchLists[listKey].itemsPerPage = Number(metadata.per_page);
			state.searchLists[listKey].errorMessage = '';
			state.searchLists[listKey].success = true;
			state.searchLists[listKey].isLoading = false;
			state.searchLists[listKey].isReady = true;
		});
		builder.addCase(loadVehicles.rejected, (state, { meta }) => {
			if (!meta.aborted) {
				const watchedVehicleIds = state.searchLists[LIST_KEYS.watchlist].vehicleIds;
				const listKey = getKeyFromSourceTab(meta.arg.sourceTab);

				const elementsFromFailedList = state.searchLists[listKey].vehicleIds.filter(
					(id) => !watchedVehicleIds.includes(id),
				);
				vehiclesAdapter.removeMany(state, elementsFromFailedList);

				state.searchLists[listKey].vehicleIds = [];
				state.searchLists[listKey].success = false;
				state.searchLists[listKey].isLoading = false;
				state.searchLists[listKey].isReady = true;
				state.searchLists[listKey].errorMessage = 'There was an error while fetching the vehicles.';
			}
		});
		// Hidde vehicle
		builder.addCase(setVehicleAsHidden.pending, (state, { meta }) => {
			state.entities[meta.arg.vehicle.id].hideVehicleRequestIsLoading = true;
		});
		builder.addCase(setVehicleAsHidden.fulfilled, (state, { meta }) => {
			const hiddenVehicle = state.entities[meta.arg.vehicle.id];
			const { searchListKey } = meta.arg;
			const { hiddenListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);
			hiddenVehicle.hideVehicleRequestIsLoading = false;
			hiddenVehicle.hidden = true;
			hiddenVehicle.watching = false;
			const hiddenSearchList = state.searchLists[hiddenListKey];

			// insert into hidden list
			insertVehicleIntoIdslist(
				state.entities,
				hiddenSearchList.vehicleIds,
				hiddenVehicle,
				hiddenSearchList.itemsPerPage,
			);
			const { totalElements: totalElementsAfterAddition, pageCount: pageCountAfterAddition } =
				calculatePaginationAfterAddition(hiddenSearchList.totalElements, hiddenSearchList.itemsPerPage);
			hiddenSearchList.totalElements = totalElementsAfterAddition;
			hiddenSearchList.pageCount = pageCountAfterAddition;

			// delete from the main source list (marketplace or auction)
			const sourceSearchList = state.searchLists[searchListKey];
			const { vehicleIds, totalElements, pageCount } = removeFromVehicleIds({
				vehicleIds: sourceSearchList.vehicleIds,
				vehicleIdToRemove: meta.arg.vehicle.id,
				totalElements: sourceSearchList.totalElements,
				itemsPerPage: sourceSearchList.itemsPerPage,
				checkPresenceOnVehicleIdsToCalculatePagination: true,
			});
			sourceSearchList.vehicleIds = vehicleIds;
			sourceSearchList.totalElements = totalElements;
			sourceSearchList.pageCount = pageCount;
			toast.success('Vehicle successfully hidden');
		});
		builder.addCase(setVehicleAsHidden.rejected, (state, { meta }) => {
			toast.error('Hide action has failed, please retry');
			state.entities[meta.arg.vehicle.id].hideVehicleRequestIsLoading = false;
		});

		// UnHide vehicle
		builder.addCase(unHideVehicle.pending, (state, { meta }) => {
			if (state.entities[meta.arg.vehicleToUnHide.id]) {
				state.entities[meta.arg.vehicleToUnHide.id].hideVehicleRequestIsLoading = true;
			}
		});
		builder.addCase(unHideVehicle.fulfilled, (state, { meta }) => {
			const hiddenVehicle = state.entities[meta.arg.vehicleToUnHide.id];

			if (!hiddenVehicle) return;

			const { searchListKey } = meta.arg;
			const { hiddenListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);
			hiddenVehicle.hideVehicleRequestIsLoading = false;
			hiddenVehicle.hidden = false;

			// delete from entities
			const nonSelectedKeys = getNotSelectedKeys(hiddenListKey);
			const otherListIds = [];
			nonSelectedKeys.forEach((nonSelectedKey) => {
				otherListIds.push(...(state.searchLists[nonSelectedKey]?.vehicleIds || []));
			});
			if (!otherListIds.includes(meta.arg.vehicleToUnHide.id)) {
				vehiclesAdapter.removeOne(state, meta.arg.vehicleToUnHide.id);
			}

			// delete from hidden list
			const hiddenSearchList = state.searchLists[hiddenListKey];
			const { vehicleIds, totalElements, pageCount } = removeFromVehicleIds({
				vehicleIds: hiddenSearchList.vehicleIds,
				vehicleIdToRemove: meta.arg.vehicleToUnHide.id,
				totalElements: hiddenSearchList.totalElements,
				itemsPerPage: hiddenSearchList.itemsPerPage,
			});
			hiddenSearchList.vehicleIds = vehicleIds;
			hiddenSearchList.totalElements = totalElements;
			hiddenSearchList.pageCount = pageCount;
			toast.success('Vehicle successfully unhidden');
		});
		builder.addCase(unHideVehicle.rejected, (state, { meta }) => {
			toast.error('Unhide has failed, please retry');
			if (state.entities[meta.arg.vehicleToUnHide.id]) {
				state.entities[meta.arg.vehicleToUnHide.id].hideVehicleRequestIsLoading = false;
			}
		});
		builder.addCase(loadVehiclesMarketplace.pending, (state, { meta }) => {
			state.searchLists[meta.arg.searchListKey].isLoading = true;
			state.searchLists[meta.arg.searchListKey].errorMessage = '';
		});
		builder.addCase(loadVehiclesMarketplace.fulfilled, (state, { payload, meta }) => {
			const { vehicles, pageCount, totalCount, page } = payload;
			const listKey = meta.arg.searchListKey;
			addVehicleToListKeyAndUpdateEntities({
				newVehicleList: vehicles,
				state,
				listKey,
				shouldDecode: !!meta?.arg?.unifiedMarketplace,
			});
			state.searchLists[listKey].isLoading = false;
			state.searchLists[listKey].isReady = true;
			state.searchLists[listKey].pageCount = pageCount;
			state.searchLists[listKey].totalElements = totalCount;
			state.searchLists[listKey].currentSearch.params.page = page;
		});
		builder.addCase(loadVehiclesMarketplace.rejected, (state, { meta, payload }) => {
			if (!meta.aborted) {
				const listKey = meta.arg.searchListKey;
				// clear list
				addVehicleToListKeyAndUpdateEntities({
					newVehicleList: [],
					state,
					listKey,
					shouldDecode: !!meta?.arg?.unifiedMarketplace,
				});
				state.searchLists[listKey].isLoading = false;
				state.searchLists[listKey].isReady = true;
				state.searchLists[listKey].errorMessage = payload?.errorMessage;
			}
		});
		// Featured
		builder.addCase(loadFeaturedVehicleCampaign.pending, (state, { meta }) => {
			state.searchLists[meta.arg.searchListKey].featured.isLoading = true;
			state.searchLists[meta.arg.searchListKey].featured.errorMessage = '';
		});
		builder.addCase(loadFeaturedVehicleCampaign.fulfilled, (state, { payload, meta }) => {
			const { vehicles, id, searchFilter, seeMoreText, title } = payload;
			const listKey = meta.arg.searchListKey;
			state.searchLists[listKey].featured.isLoading = false;
			state.searchLists[listKey].featured.showFeaturedVehicles = true;
			state.searchLists[listKey].featured.id = id;
			state.searchLists[listKey].featured.searchFilter = searchFilter;
			state.searchLists[listKey].featured.seeMoreText = seeMoreText;
			state.searchLists[listKey].featured.title = title;
			addVehicleToListKeyAndUpdateEntities({
				state,
				newVehicleList: vehicles,
				listKey,
				isFeaturedVehicleList: true,
			});
		});
		builder.addCase(loadFeaturedVehicleCampaign.rejected, (state, { meta, error }) => {
			state.searchLists[meta.arg.searchListKey].featured.isLoading = false;
			state.searchLists[meta.arg.searchListKey].featured.errorMessage =
				error?.message || 'Error getting featured vehicles';
		});
		builder.addCase(closeCampaign.fulfilled, (state, { meta }) => {
			const listKey = meta.arg.searchListKey;
			state.searchLists[listKey].featured.showFeaturedVehicles = false;
		});
	},
});

export const buyerTimedSaleVehiclesSliceActions = buyerTimedSaleVehiclesSlice.actions;
export const {
	/* Export ///NEW ACTIONS */
	startBid,
	endBid,
	updateBid,
	setOvertimeCompleted,
	removeVehicleFromCurrentPage,
	addVehicleToOfferList,
	addToWatchlist,
	removeFromWatchlist,
	setFocussedTab,
	clearFeaturedVehicleCampaign,
	clearAllVehiclesList,
	moveVehiclesToLiveOnAuctionStart,
	setUnifiedMarketplaceSaleType,
} = buyerTimedSaleVehiclesSlice.actions;

export const {
	selectById: selectVehicleById,
	selectIds: selectVehicleIds,
	selectEntities: selectVehicleEntities,
	selectAll: selectAllVehicles,
	selectTotal: selectTotalVehicles,
} = vehiclesAdapter.getSelectors((state) => state.buyerVehicles);

export default buyerTimedSaleVehiclesSlice.reducer;
