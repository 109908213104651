/* eslint-disable camelcase */
import * as snippet from '@segment/snippet';
import { QA_ENVIRONMENTS } from 'constants/environments';
import Script from 'next/script';
import { notifyError } from '../../errorNotifier';
import { useEffect } from 'react';
import { useState } from 'react';
import useValidateSegment from 'hooks/useValidateSegment';
import { publicEnvConfig } from 'helpers/env';

export default function SegmentSnippet() {
	const enableSegment = useValidateSegment();

	const [segmentSnippet, setSegmentSnippet] = useState();
	const options = {
		apiKey: publicEnvConfig.SEGMENT_WRITE_KEY,
		page: false,
	};

	// this is to force segment to be loaded on client side
	useEffect(() => {
		if (enableSegment) {
			try {
				const snippetFromSegment =
					QA_ENVIRONMENTS.includes(publicEnvConfig.RELEASE_STAGE) ?
						snippet.max(options)
					:	snippet.min(options);
				setSegmentSnippet(snippetFromSegment);
			} catch {
				notifyError('Error loading segment snippet');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableSegment]);

	return segmentSnippet ? <Script dangerouslySetInnerHTML={{ __html: segmentSnippet }} id="segment-snippet" /> : null;
}
