export const QA_ENVIRONMENTS = [
	'feature',
	'staging',
	'teamfinance',
	'teammobile',
	'teamtransport',
	'teaminspection',
	'teammarketplace',
	'teammarketplace2',
	'teammarketplace3',
	'teamsaperium',
	'tbd',
	'timedsales',
	'loadtest',
	'ondemand',
	'development',
];

export const PRODUCTION_ENVIRONMENT = 'production';

export const NON_DEVELOP_ENVIRONMENTS = [PRODUCTION_ENVIRONMENT, ...QA_ENVIRONMENTS];
