import { notifyError } from 'helpers/errorNotifier';
import { publicEnvConfig } from 'helpers/env';

const parseConfigurableEndpoint = (endpoint) => {
	if (!endpoint) return;
	try {
		const { pathname, searchParams } = new URL(endpoint, publicEnvConfig.RESTURL_BASE);
		const queryObject = Object.fromEntries(searchParams.entries());
		return { configurableEndpoint: pathname, configurableParams: queryObject };
	} catch (error) {
		notifyError(`Invalid configurable endpoint: ${JSON.stringify(error)}`);
	}
};

export default parseConfigurableEndpoint;
