import apiV3HeadersInterceptor from './apiV3HeadersInterceptor';
import refreshJWTWithRefreshToken from './refreshJWTWithRefreshToken';

export const addAuthInterceptor = (config, restClientInstance) => {
	if (config.sendAuthHeaders && !config.externalRequest) {
		restClientInstance._axios.interceptors.request.use((config) =>
			refreshJWTWithRefreshToken(config, restClientInstance),
		);
	}
};

export const addAPIV3HeadersInterceptor = (config, restClientInstance) => {
	if (config.sendAPIV3Headers && !config.externalRequest) {
		restClientInstance._axios.interceptors.request.use((config) =>
			apiV3HeadersInterceptor(config, restClientInstance),
		);
	}
};
