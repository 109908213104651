import { VEHICLE_STATUS } from 'constants/vehicle';
import { deal_working, ended, live_or_extended, sold } from 'helpers/timedSales/auctionStatusScenarios';

export const isCurrentUserTheTopOfferer = ({ topOffererDealershipId, dealershipsId }) => {
	return topOffererDealershipId && dealershipsId.includes(topOffererDealershipId);
};

const calculateSummaryForAuctionItem = (
	{ leaderCount, outbiddedCount, wonCount, lostCount, counterCount },
	isUserTheTopOfferer,
	bidSale,
) => {
	if (live_or_extended(bidSale.auction_status)) {
		if (deal_working(bidSale.auction_status)) {
			isUserTheTopOfferer ? counterCount++ : lostCount++;
		} else {
			isUserTheTopOfferer ? leaderCount++ : outbiddedCount++;
		}
	}
	if (sold(bidSale.auction_status) || (ended(bidSale.auction_status) && bidSale.status !== VEHICLE_STATUS.expired)) {
		isUserTheTopOfferer ? wonCount++ : lostCount++;
	}

	return { leaderCount, outbiddedCount, wonCount, lostCount, counterCount };
};

const calculateSummaryForMarketplaceItem = (
	{ leaderCount, outbiddedCount, wonCount, lostCount, counterCount },
	isUserTheTopOfferer,
	bidSale,
) => {
	if (bidSale.status === VEHICLE_STATUS.sold) {
		isUserTheTopOfferer ? wonCount++ : lostCount++;
	} else if (bidSale.status === VEHICLE_STATUS.active) {
		if (Boolean(bidSale.acceptable_counter_offer_activity)) {
			counterCount++;
		} else {
			isUserTheTopOfferer ? leaderCount++ : outbiddedCount++;
		}
	}

	return { leaderCount, outbiddedCount, wonCount, lostCount, counterCount };
};

export const calculateSummaryFromList = ({ bidSalesThatUserHasOfferer, dealershipsId }) => {
	const offerSummary = bidSalesThatUserHasOfferer.reduce(
		({ leaderCount, outbiddedCount, wonCount, lostCount, counterCount }, bidSale) => {
			const { top_offerer_dealership_id: topOffererDealershipId } = bidSale;
			const isUserTheTopOfferer = isCurrentUserTheTopOfferer({
				topOffererDealershipId,
				dealershipsId,
			});

			const calcMethod =
				bidSale.auction_status ? calculateSummaryForAuctionItem : calculateSummaryForMarketplaceItem;
			return calcMethod(
				{ leaderCount, outbiddedCount, wonCount, lostCount, counterCount },
				isUserTheTopOfferer,
				bidSale,
			);
		},
		{ leaderCount: 0, outbiddedCount: 0, wonCount: 0, lostCount: 0, counterCount: 0 },
	);

	return offerSummary;
};
