import { notifyError } from 'helpers/errorNotifier';

export const safeAtob = (value) => {
	if (!value || typeof value === 'number') return value;
	try {
		return window.atob(value);
	} catch {
		notifyError(`'Failed to execute 'atob' on 'Window', for text '${value}'`);
		return value;
	}
};
