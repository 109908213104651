import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { getMarketplaceSourceTabFromTabName, MARKETPLACE_TAB } from 'constants/marketplace';
import { getVdpURL } from 'helpers/externalRoutes';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';

/* eslint-disable camelcase */
export function buildParamsURL(params, defaultFilters, addDefaults = true) {
	let result = [];
	for (var par in params) {
		if (params.hasOwnProperty(par) && params[par] !== undefined && params[par] !== null && params[par] !== '') {
			if (addDefaults || params[par] !== defaultFilters?.[par]) {
				result.push(encodeURIComponent(par) + '=' + encodeURIComponent(params[par]));
			}
		}
	}
	return result.join('&');
}

export function sanitizeMaxMin(from, to, min, max) {
	if (!from) from = min;
	if (!to) to = max;

	if (from > to) {
		[from, to] = [to, from]; //swap
	}
	if (from < min) from = min;
	if (to > max) to = max;

	return [from, to];
}

export const checkboxIsChecked = (params, prop, slug, checkIfThereIsChildSelected) => {
	const list = params[prop] && typeof params[prop] === 'string' ? params[prop].split(',') : params[prop];

	if (checkIfThereIsChildSelected) {
		// Easy way to check if there is a child selected without loading the children first
		const isChildChecked =
			list &&
			list?.some((value) =>
				typeof value === 'string' ? value.toLowerCase().startsWith(`${slug}_`.toLowerCase()) : value,
			);
		if (isChildChecked) {
			return true;
		}
	}

	return Boolean(
		list &&
			list.some((value) =>
				typeof value === 'string' ? value.toLowerCase() === slug.toLowerCase() : value === slug,
			),
	);
};

export const saleTypesForRadio = ['buy_it_now_only', 'sealed_bid_only'];
export const sellerTypesForRadio = [
	'seller_type_franchise',
	'seller_type_independent',
	'seller_type_private_party_remarketer',
];

export function sanitizeFilters(params, acceptedFilters, defaultFilters) {
	const filters = Object.keys(acceptedFilters);
	const urlParams = { ...params };
	filters.forEach((filter) => {
		if (filter in urlParams) {
			let type = acceptedFilters[filter].type;
			switch (type) {
				case 'min':
					urlParams[filter] = sanitizeMin(urlParams, acceptedFilters, defaultFilters, filter);
					break;
				case 'max':
					urlParams[filter] = sanitizeMax(urlParams, acceptedFilters, defaultFilters, filter);
					break;
				case 'integer':
					urlParams[filter] = parseInt(urlParams[filter]);
					break;
				case 'boolean':
					urlParams[filter] = sanitizeBoolean(urlParams, acceptedFilters, defaultFilters, filter);
					break;
				case 'radius':
					urlParams[filter] = sanitizeRadius(urlParams, acceptedFilters, defaultFilters, filter);
					break;
				case 'array':
					urlParams[filter] = sanitizeArray(urlParams, acceptedFilters, defaultFilters, filter);
					break;
			}
		}
	});

	return urlParams;
}

function sanitizeRadius(actual, acceptedFilters, defaultFilters, key) {
	const value = parseInt(actual[key]);
	if (isNaN(value) || value < defaultFilters['min_radius'] || value > defaultFilters['max_radius']) {
		return defaultFilters['max_radius'];
	}
	return value;
}

function sanitizeBoolean(actual, acceptedFilters, defaultFilters, key) {
	let valid_boolean = [0, 1, '0', '1', 'true', 'false', true, false].includes(actual[key]);
	if (valid_boolean) {
		return actual[key] == 'true' || actual[key] == '1';
	}
	return defaultFilters[key];
}

function sanitizeMin(actual, acceptedFilters, defaultFilters, key) {
	const value = parseInt(actual[key]);
	if (isNaN(value) || value < defaultFilters[key] || value > defaultFilters[acceptedFilters[key].related_to]) {
		return defaultFilters[key];
	}
	return value;
}

function sanitizeMax(actual, acceptedFilters, defaultFilters, key) {
	const value = parseInt(actual[key]);
	if (isNaN(actual[key]) || value > defaultFilters[key] || value < defaultFilters[acceptedFilters[key].related_to]) {
		return defaultFilters[key];
	}
	return value;
}

function sanitizeArray(actual, acceptedFilters, defaultFilters, key) {
	if (['scope', 'direction', 'order_by'].includes(key)) {
		return actual[key];
	}

	let value = actual[key] ? actual[key].split(',') : [];
	value.forEach((txt) => {
		if (!acceptedFilters?.[key]?.possible_values?.includes(txt)) {
			return defaultFilters[key];
		}
	});

	return value;
}

export function buildURLForVehicleRedirection({ vehicle, params, refUrl, generateUrlLocally, ...rest }) {
	const paramsUrl = buildParamsURL({ ...params, ref_url: refUrl, ...rest });
	const url =
		Boolean(vehicle.branch_url && !generateUrlLocally) ? `${vehicle.branch_url}` : `${getVdpURL(vehicle.id)}`;
	return `${url}${paramsUrl ? `?${paramsUrl}` : ''}`;
}

export const isEmptyFilter = (value) =>
	value === undefined || value === null || value === '' || (Array.isArray(value) && !value.length);

export function bothAreEmptyOrNullable(defaultValue, currentValue) {
	return isEmptyFilter(defaultValue) && isEmptyFilter(currentValue);
}

export function areFiltersModified(defaultFilters, params) {
	return Object.entries(defaultFilters).some(
		([key, defaultValue]) =>
			params[key] !== undefined &&
			!bothAreEmptyOrNullable(defaultValue, params[key]) &&
			JSON.stringify(params[key]) !== JSON.stringify(defaultValue),
	);
}

export const acceptedQueryParams = {
	tab: {
		type: 'stringFromList',
		possible_values: [...Object.values(MARKETPLACE_TAB), ...Object.values(BID_SALE_SOURCE_TAB)],
	},
};

// remove when tbd_187929_unified_marketplace_react is fully enabled
export const acceptedQueryParamsForUnifiedMarketplace = {
	tab: {
		type: 'stringFromList',
		possible_values: Object.values(MARKETPLACE_TAB),
	},
};

export const sanitizeStringFromList = (key, value, params) =>
	params[key]?.possible_values?.find((possibleValue) => possibleValue === value);

export const CONTEXT = {
	auction: 'auction',
	marketplace: 'marketplace',
};

export const getSaveSearchContext = (isAuction) => (isAuction ? CONTEXT.auction : CONTEXT.marketplace);

const sourceTabFromSearchListKeyMap = {
	auction: 'search',
	hidden: 'hidden',
	offers: 'offers',
	watchlist: 'watchlist',
};

export const getSourceTabFromSearchListKey = (searchListKey) =>
	sourceTabFromSearchListKeyMap[searchListKey] || getMarketplaceSourceTabFromTabName(searchListKey);

export const getListContextFromSearchListKey = (searchListKey) =>
	getMarketplaceSourceTabFromTabName(searchListKey) ? CONTEXT.marketplace : CONTEXT.auction;

const CONTEXT_DEFAULT_LIST_KEY = {
	[CONTEXT.auction]: LIST_KEYS.auction,
	[CONTEXT.marketplace]: MARKETPLACE_TAB.marketplace,
};

export const getDefaultSearchListKeyForContext = (contextName) => CONTEXT_DEFAULT_LIST_KEY[contextName];
