import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { getAdminUsers, getAllBidSales } from './dealDriver.thunks';
import { initialState } from './constants';

// Create an entity adapter for bidSales
const bidSalesAdapter = createEntityAdapter();

export const dealDriverSlice = createSlice({
	name: 'dealDriver',
	initialState: {
		...initialState,
		data: bidSalesAdapter.getInitialState(), // Use entity adapter's initial state for data
	},
	reducers: {
		updateBidSale: (state, { payload }) => {
			const { id, changes } = payload;
			bidSalesAdapter.updateOne(state.data, { id, changes });
		},
		updateFilters: (state, { payload }) => {
			state.filters = { ...state.filters, ...payload };
		},
		updateSorting: (state, { payload }) => {
			state.sorting = payload;
		},
		updateAdminUsersFormData: (state, { payload }) => {
			state.adminUsers.formData = { ...state.adminUsers.formData, ...payload };
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllBidSales.pending, (state) => {
				state.loading = true;
				state.success = undefined;
			})
			.addCase(getAllBidSales.fulfilled, (state, { payload }) => {
				const { bidSales, metadata } = payload;
				bidSalesAdapter.setAll(state.data, bidSales); // Use adapter to set all bidSales
				state.metadata = metadata;
				state.loading = false;
				state.success = true;
			})
			.addCase(getAllBidSales.rejected, (state) => {
				state.loading = false;
				state.success = false;
			})
			.addCase(getAdminUsers.pending, (state) => {
				state.adminUsers.loading = true;
				state.adminUsers.success = undefined;
			})
			.addCase(getAdminUsers.fulfilled, (state, { payload }) => {
				const { adminUsers, metadata } = payload;
				state.adminUsers.data = adminUsers;
				state.adminUsers.metadata = metadata;
				state.adminUsers.loading = false;
				state.adminUsers.success = true;
			})
			.addCase(getAdminUsers.rejected, (state) => {
				state.adminUsers.loading = false;
				state.adminUsers.success = false;
			});
	},
});

export const { updateBidSale, updateFilters, updateSorting, updateAdminUsersFormData } = dealDriverSlice.actions;

// Export selectors from the entity adapter
export const { selectIds: selectAllBidSalesIds, selectById: selectBidSaleById } = bidSalesAdapter.getSelectors(
	(state) => state.dealDriver.data,
);

export default dealDriverSlice.reducer;
