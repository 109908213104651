import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMarketplaceSourceTabFromTabName, MARKETPLACE_TAB } from 'constants/marketplace';
import STATUS_CODES from 'constants/statusCodes';
import { authenticatedRestClient } from 'helpers/restclient';
import featuredVehiclesCampaignService from 'services/featuredVehiclesCampaign/featuredVehiclesCampaign.service';
import { getFiltersParams } from 'store/slices/buyers/filters/filtersSelectors';
import { getRefreshDataForVehicleIds } from 'store/slices/buyerVehicles/thunks/helpers/getOfferRefreshData';

export const shouldLoadFeaturedVehicleCampaign = ({ page, searchListKey }) =>
	page == 1 && searchListKey === MARKETPLACE_TAB.marketplace;

export const loadFeaturedVehicleCampaign = createAsyncThunk(
	'loadFeaturedVehicleCampaign',
	async ({ searchListKey, context }, thunkAPI) => {
		const cancelSource = authenticatedRestClient.getCancelToken();

		thunkAPI.signal.addEventListener('abort', () => {
			cancelSource.cancel();
		});

		// we are sending search params because depending on it, it can change or hide recommendations
		const { params } = getFiltersParams(thunkAPI.getState(), searchListKey);
		const featuredVehicleCampaign = await featuredVehiclesCampaignService.instance.getFeaturedVehicleCampaign({
			searchListParams: {
				...params,
				source_tab: getMarketplaceSourceTabFromTabName(searchListKey),
				context,
				// Remove when deleting tbd_187929_unified_marketplace_react mixed experience
				sale_types: thunkAPI.getState().buyerVehicles.saleType || params.sale_types,
			},
		});

		if (featuredVehicleCampaign?.status !== STATUS_CODES.OK) throw { name: 'failed', message: 'featured failed' };

		const { id, search_filter, see_more_text, title, vehicles } = featuredVehicleCampaign.data.data[0];
		const refreshedVehicles = await getRefreshDataForVehicleIds({
			vehicles,
			searchListKey,
			isFeaturedVehicleCampaign: true,
		});

		return {
			vehicles: refreshedVehicles,
			id,
			searchFilter: search_filter,
			seeMoreText: see_more_text,
			title: title,
		};
	},
);

export const closeCampaign = createAsyncThunk('closeCampaign', async ({ searchListKey, id }, thunkAPI) => {
	const cancelSource = authenticatedRestClient.getCancelToken();

	thunkAPI.signal.addEventListener('abort', () => {
		cancelSource.cancel();
	});

	try {
		await featuredVehiclesCampaignService.instance.closeFeaturedVehicleCampaign({
			campaignId: id,
			sourceTab: getMarketplaceSourceTabFromTabName(searchListKey),
			context: 'marketplace',
		});
	} catch {}

	return {
		showFeaturedVehicles: false,
	};
});
