import { toast } from '@Backlot-Cars/archie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BID_SALE_SOURCE_TAB, isAuctionSourceTab } from 'constants/bidSale';
import { getMarketplaceSourceTabFromTabName, MARKETPLACE_TAB } from 'constants/marketplace';
import STATUS_CODES from 'constants/statusCodes';
import { CONTEXT } from 'helpers/search';
import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import BuysideSearchService from 'services/timedSales/buyside/search.service';
import { getListKeysForWatchlistAndHiddenActions } from 'store/helpers/watchlist';
import { updateSyncedTabsAfterFetch } from 'store/slices/auctionPoolingUpdater/auctionPoolingSlice';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';
import { getRefreshDataForVehicleIds } from 'store/slices/buyerVehicles/thunks/helpers/getOfferRefreshData';
import { offerSummaryWasLoadedSelector } from 'store/slices/offerSummary/offerSummarySelector';
import { setAuctionsStatus } from '../auctionStatus/slice';
import { getFiltersParams } from '../buyers/filters/filtersSelectors';
import { updateAppliedFilters } from '../buyers/filters/filtersSlice';
import * as localFunctions from './buyerVehiclesThunks';
import parseConfigurableEndpoint from './helpers/parseConfigurableEndpoint';
import { loadFeaturedVehicleCampaign, shouldLoadFeaturedVehicleCampaign } from './thunks/featuredVehicles';
import { removeVehicleFromWatchlist } from './thunks/watchlist';

const NUMBER_OF_HIDE_ACTIONS_BEFORE_RELOAD_AUCTION_LIST = 10;

export const loadVehicles = createAsyncThunk(
	'loadVehicles',
	async ({ sourceTab = BID_SALE_SOURCE_TAB.auction, page, searchListKey }, thunkAPI) => {
		const source = axios.CancelToken.source();
		thunkAPI.signal.addEventListener('abort', () => {
			source.cancel();
		});

		const { params: searchParams } = getFiltersParams(thunkAPI.getState(), searchListKey);
		const isOfferList = searchListKey === BID_SALE_SOURCE_TAB.offers;

		const shouldApplySearchFilters = sourceTab !== BID_SALE_SOURCE_TAB.hidden;
		let filters = {
			source_tab: sourceTab,
		};

		if (shouldApplySearchFilters) {
			filters = {
				...searchParams,
				...filters,
			};
		}

		const params = { ...filters, page: page || searchParams.page };

		let data;
		if (isOfferList) {
			const offersParams = { ...params, context: CONTEXT.auction };
			const { data: offerData } = await BuysideSearchService.instance.getOffers(
				offersParams,
				false,
				source.token,
			);
			data = offerData;
		} else {
			const { data: bidSalesData } = await BidSalesService.instance.getVehicles(params, source.token);
			data = bidSalesData;
		}

		const {
			data: { auctions },
			applied_filters: appliedFilters,
		} = data;

		if (auctions) thunkAPI.dispatch(setAuctionsStatus(auctions)); // Shall we maybe put all of this in the same slice?
		if (appliedFilters) thunkAPI.dispatch(updateAppliedFilters({ appliedFilters, listKey: searchListKey }));

		// Set on pooling slice that this tab was updated data due to fetch
		thunkAPI.dispatch(updateSyncedTabsAfterFetch({ syncedTab: sourceTab }));

		return data;
	},
);

const getPageToReloadAfterSuccessfullHide = (
	itemsPerPage,
	itemsOnCurrentPage,
	totalElements,
	currentPage,
	pageCount,
) => {
	const isOnLastPage = currentPage === pageCount;
	const itemsOnCurrentPageAfterHide = itemsOnCurrentPage - 1;
	const thereAreMoreElementsOnOtherPages = totalElements - 1 > itemsOnCurrentPageAfterHide;
	const isPageEmpty = itemsOnCurrentPageAfterHide === 0;
	const hiddenActionsNumberExceeded =
		itemsOnCurrentPageAfterHide <= itemsPerPage - NUMBER_OF_HIDE_ACTIONS_BEFORE_RELOAD_AUCTION_LIST;

	if (isPageEmpty && thereAreMoreElementsOnOtherPages) {
		return isOnLastPage ? currentPage - 1 : currentPage;
	}
	if (hiddenActionsNumberExceeded && thereAreMoreElementsOnOtherPages && !isOnLastPage) return currentPage;

	return false;
};

export const setVehicleAsHidden = createAsyncThunk(
	'setVehicleAsHidden',
	async ({ vehicle, vdpType, sourceTab, searchListKey, unifiedMarketplace }, thunkAPI) => {
		const { watchlistListKey, sourceListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);
		const isAuction = isAuctionSourceTab(searchListKey);

		const {
			data: { success },
		} = await BidSalesService.instance.hideVehicle(vehicle.id, vdpType, sourceTab);

		const vehicleListState = thunkAPI.getState().buyerVehicles;

		// if it's present on watchlist delete it
		const watchlistIds = vehicleListState.searchLists[watchlistListKey].vehicleIds;
		if (watchlistIds.includes(vehicle.id)) {
			thunkAPI.dispatch(removeVehicleFromWatchlist({ vehicle, isAuction, unifiedMarketplace }));
		}

		// Reload auction / marketplace list if needed
		const {
			pageCount,
			vehicleIds,
			totalElements,
			itemsPerPage,
			currentSearch: {
				params: { page: currentPage },
			},
		} = vehicleListState.searchLists[sourceListKey];
		const pageToReload = getPageToReloadAfterSuccessfullHide(
			itemsPerPage,
			vehicleIds.length,
			totalElements,
			currentPage,
			pageCount,
		);

		if (pageToReload) {
			// TODO: we should find a way to make request cancelable from different components
			if (sourceListKey === MARKETPLACE_TAB.marketplace) {
				thunkAPI.dispatch(
					localFunctions.loadVehiclesMarketplace({
						page: pageToReload,
						searchListKey: MARKETPLACE_TAB.marketplace,
						unifiedMarketplace,
					}),
				);
			} else if (sourceListKey === LIST_KEYS.auction) {
				thunkAPI.dispatch(
					localFunctions.loadVehicles({
						page: pageToReload,
						searchListKey: LIST_KEYS.auction,
					}),
				);
			}
		}

		return success;
	},
);

export const unHideVehicle = createAsyncThunk(
	'unHideVehicle',
	async ({ vehicleToUnHide, vdpType, sourceTab, searchListKey, unifiedMarketplace }, thunkAPI) => {
		const {
			data: { success },
		} = await BidSalesService.instance.unHideVehicle(vehicleToUnHide.id, vdpType, sourceTab);

		const vehicleListState = thunkAPI.getState().buyerVehicles;

		// TODO: we should find a way to make request cancelable from different components
		const { hiddenListKey, sourceListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);

		// Reload main source list on unhide (marketplace or auction)
		const pageToReload = vehicleListState.searchLists[sourceListKey].currentSearch.params.page;
		if (sourceListKey === MARKETPLACE_TAB.marketplace) {
			thunkAPI.dispatch(
				localFunctions.loadVehiclesMarketplace({
					page: pageToReload,
					searchListKey: MARKETPLACE_TAB.marketplace,
				}),
			);
		} else if (sourceListKey === LIST_KEYS.auction) {
			thunkAPI.dispatch(
				localFunctions.loadVehicles({
					page: pageToReload,
					searchListKey: LIST_KEYS.auction,
				}),
			);
		}
		// Reload hidden list if needed
		const { pageCount, vehicleIds } = vehicleListState.searchLists[hiddenListKey];
		const currentPage = vehicleListState.searchLists[hiddenListKey].currentSearch.params.page;
		const thereAreFollowingPages = currentPage < pageCount;
		const deletingLastElementFromPage = currentPage !== 1 && vehicleIds.length === 1;

		if (thereAreFollowingPages || deletingLastElementFromPage) {
			const page = deletingLastElementFromPage ? currentPage - 1 : currentPage;
			if (sourceListKey === MARKETPLACE_TAB.marketplace) {
				thunkAPI.dispatch(
					localFunctions.loadVehiclesMarketplace({
						page: pageToReload,
						searchListKey: MARKETPLACE_TAB.hidden,
						unifiedMarketplace,
					}),
				);
			} else if (sourceListKey === LIST_KEYS.auction) {
				thunkAPI.dispatch(
					localFunctions.loadVehicles({
						page,
						sourceTab: BID_SALE_SOURCE_TAB.hidden,
						searchListKey: LIST_KEYS.hidden,
						unifiedMarketplace,
					}),
				);
			}
		}
		return success;
	},
);

// ----------------
// Marketplace
// ----------------
export const loadVehiclesMarketplace = createAsyncThunk(
	'loadVehiclesMarketplace',
	async (
		{ page, closedTradeNetwork, errorMessage, searchListKey, skipVehicleCampaign, unifiedMarketplace, endpoint },
		thunkAPI,
	) => {
		const { rejectWithValue, fulfillWithValue, signal, getState } = thunkAPI;
		const state = getState();
		const isOfferList = searchListKey === MARKETPLACE_TAB.offers;

		const { configurableEndpoint, configurableParams } = parseConfigurableEndpoint(endpoint) || {};

		const { params: searchParams } = getFiltersParams(state, searchListKey);

		const currentPage = page || searchParams.page || 1;

		if (!skipVehicleCampaign && shouldLoadFeaturedVehicleCampaign({ page: currentPage, searchListKey })) {
			thunkAPI.dispatch(loadFeaturedVehicleCampaign({ searchListKey, context: CONTEXT.marketplace }));
		}

		const source = axios.CancelToken.source();
		signal.addEventListener('abort', () => {
			source.cancel();
		});

		const filters = {
			...searchParams,
			...configurableParams,
			source_tab: getMarketplaceSourceTabFromTabName(searchListKey),
			page: currentPage,
			// Remove when deleting tbd_187929_unified_marketplace_react mixed experience
			sale_types: state.buyerVehicles.saleType || searchParams.sale_types,
		};

		try {
			let searchResult;

			if (isOfferList && !unifiedMarketplace) {
				searchResult = await BuysideSearchService.instance.getOffers(
					filters,
					closedTradeNetwork,
					source.token,
					unifiedMarketplace,
				);
			} else {
				searchResult = await BuysideSearchService.instance.searchVehicles(
					filters,
					closedTradeNetwork,
					source.token,
					unifiedMarketplace,
					configurableEndpoint,
				);
			}

			if (searchResult?.status !== STATUS_CODES.OK) return rejectWithValue({ errorMessage });

			const closedMarketplace = closedTradeNetwork || configurableParams?.marketplace_ids;
			if (closedMarketplace && searchResult?.data?.messages?.length > 0) {
				return rejectWithValue({ errorMessage: searchResult?.data?.messages[0] });
			}

			const {
				applied_filters: appliedFilters,
				metadata,
				data: { checkout_warning, vehicles },
			} = searchResult.data;

			if (checkout_warning?.show) toast.warning(checkout_warning.message);
			if (appliedFilters) thunkAPI.dispatch(updateAppliedFilters({ appliedFilters, listKey: searchListKey }));

			let refreshedVehicles = vehicles;

			refreshedVehicles = await getRefreshDataForVehicleIds({
				vehicles,
				searchListKey,
				includeSummary: unifiedMarketplace,
				offerSummaryWasLoaded: offerSummaryWasLoadedSelector(thunkAPI.getState()),
				dispatch: thunkAPI.dispatch,
				offerSummarySaleType: state.buyerVehicles.saleType,
			});

			thunkAPI.dispatch(updateSyncedTabsAfterFetch({ syncedTab: searchListKey }));
			return fulfillWithValue({
				pageCount: metadata.page_count,
				totalCount: metadata.total_count,
				page: Number(metadata.page),
				vehicles: refreshedVehicles,
			});
		} catch (res) {
			return rejectWithValue({ errorMessage });
		}
	},
);

export const getURLParams = (urlParams, notAllowedParams) =>
	Object.keys(notAllowedParams).reduce((prev, key) => {
		if (key in prev) return { ...prev, [key]: notAllowedParams[key] };
		return prev;
	}, urlParams);
