import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const NOT_APPLICABLE = 'N/A';

export const isNullable = (value) => value === null || value === undefined;

export const formatPhoneNumber = (str) => {
	const cleaned = ('' + str).replace(/\D/g, '');

	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		const intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return null;
};

export const simpleDate = (dateString) => {
	if (!dateString) return '';

	return dayjs.utc(dateString).format('MM/DD/YYYY');
};

export const shortDateFormat = (dateString) => {
	if (!dateString) return '';

	return dayjs.utc(dateString).format('MMM DD');
};

export const longDateFormat = (dateString) => {
	if (!dateString) return '';

	return dayjs.utc(dateString).format('MMMM DD, YYYY');
};

export const formatToLocaleString = (value, showEmptyString) => {
	if (value) {
		return Number(value).toLocaleString('en-US');
	}
	return showEmptyString ? '' : '0';
};

export const formatToFixed = (value) => {
	return Number(value).toFixed(1);
};

export const pricify = (value, showSymbol = true) => {
	if (!value && value != 0) return;

	let price = typeof value === 'string' ? parseFloat(value) : value;

	const sign = price < 0 ? '-' : '';
	price = Math.abs(price);

	return `${sign}${showSymbol ? '$' : ''}${price.toLocaleString('en-US')}`;
};

export const rangeFiltersFormatter = (format, value, minValue, maxValue) => {
	switch (format) {
		case 'amount':
			return value == maxValue ? `${pricify(value || '0')}+` : pricify(value || '0');
		case 'fixed':
			return value == maxValue ? `${formatToFixed(value)}+` : formatToFixed(value);
		case 'localeString':
			return value == maxValue ? `${formatToLocaleString(value)}+` : formatToLocaleString(value);
		case 'year':
			return value == minValue ? `<${value}` : value;
	}

	return value;
};

const invalidSeconds = (seconds) => seconds == null || seconds == undefined || seconds < 0;
const showSeconds = (seconds) => seconds < 60;
const showMinutes = (seconds) => seconds < 3600;
const showHours = (seconds) => seconds < 86400;

const getTimeObject = (seconds, abbrevUnit) => {
	let timeObject;

	if (invalidSeconds(seconds)) {
		timeObject = null;
	} else if (showSeconds(seconds)) {
		timeObject = { value: seconds, unit: abbrevUnit ? 'sec' : 'second' };
	} else if (showMinutes(seconds)) {
		timeObject = { value: Math.ceil(seconds / 60), unit: abbrevUnit ? 'min' : 'minute' };
	} else if (showHours(seconds)) {
		timeObject = { value: Math.ceil(seconds / 60 / 60), unit: 'hour' };
	} else {
		timeObject = { value: Math.round(((seconds / 60 / 60 / 24) * 10) / 10), unit: 'day' };
	}

	return timeObject;
};

export function toPrettyTime(seconds, abbrevUnit = true) {
	const timeObject = getTimeObject(seconds, abbrevUnit);
	if (timeObject) {
		return `${timeObject.value} ${timeObject.unit}${timeObject.value === 1 ? '' : 's'}`;
	}
	return NOT_APPLICABLE;
}

export function toDays(value) {
	if (value === null || value === undefined) {
		return NOT_APPLICABLE;
	}

	const fixedValue = value.toFixed(1);
	return `${fixedValue} ${fixedValue === '1.0' ? 'day' : 'days'}`;
}

export function toPercentage(value) {
	if (value === null || value === undefined) {
		return NOT_APPLICABLE;
	}
	return value + '%';
}
