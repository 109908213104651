import DealDriverService from 'services/dealDriver/dealDriver.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAllBidSales = createAsyncThunk('dealDriver/getAllBidSales', async ({ page = 1 } = {}, { getState }) => {
	const {
		dealDriver: { filters, sorting },
	} = getState();
	const response = await DealDriverService.instance.getAll({ page, filters, sorting });
	const { data, metadata } = response?.data || {};
	return { bidSales: data, metadata };
});

export const getAdminUsers = createAsyncThunk('dealDriver/getAdminUsers', async ({ page = 1 } = {}, { getState }) => {
	const { dealDriver } = getState();
	const { name } = dealDriver.adminUsers.formData;
	const response = await DealDriverService.instance.getAdminUsers({ page, name });
	const { data, metadata } = response?.data || {};
	return { adminUsers: data, metadata };
});
