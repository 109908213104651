import { withCookiesRestClient } from 'helpers/restclient';
import { applyParams } from './applyParams';

const ADMIN_API_PREFIX = '/api/admin';

export default class DealDriverService {
	static instance = new DealDriverService();

	_endpoints = {
		getAll: `${ADMIN_API_PREFIX}/bid_sales`,
		getBidSaleDetails: (bidSaleId) => `${ADMIN_API_PREFIX}/bid_sales/${bidSaleId}`,
		manageDeal: (id, entity) => `${ADMIN_API_PREFIX}/bid_sales/${id}/manage_deal/${entity.toLowerCase()}`,
		getAdminUsers: `${ADMIN_API_PREFIX}/admin_users`,
	};

	constructor() {
		this.restClient = withCookiesRestClient;
	}

	getAll = (config) => {
		const params = {
			page: config.page || 1,
		};

		applyParams(config.filters, params);

		applyParams(config.sorting, params);

		return this.restClient.get(this._endpoints.getAll, params);
	};

	getBidSaleDetails = (bidSaleId) => {
		const endpoint = this._endpoints.getBidSaleDetails(bidSaleId);
		return this.restClient.get(endpoint);
	};

	manageDeal = (id, entity, data) => {
		const endpoint = this._endpoints.manageDeal(id, entity);

		return this.restClient.post(endpoint, data);
	};

	getAdminUsers = (config) => {
		let endpoint = `${this._endpoints.getAdminUsers}?page=${config.page || 1}&name=${config.name}`;

		return this.restClient.get(endpoint);
	};
}
