import { getConditionReportPageUrl } from 'helpers/conditionReport';
import { buildURLForVehicleRedirection } from 'helpers/search';
import { createAbsoluteUrlFromPath } from 'helpers/url';
import { publicEnvConfig } from 'helpers/env';

export const getStockWaveVehicleInfoJson = (vehicle, disablePartnerScript) => {
	const {
		year,
		make,
		model,
		trim,
		engine,
		drivetrain,
		transmission,
		mileage,
		vin,
		pickup_zip,
		exterior_color,
		interior_color,
		interior_type,
		cr_grade,
		token,
	} = vehicle || {};
	const stockWaveVehicleInformationObject = {
		Vin: vin,
		Year: year,
		Make: make,
		Model: model,
		PickupZip: pickup_zip,
		Trim: trim,
		Engine: engine,
		Drivetrain: drivetrain,
		Transmission: transmission,
		Odometer: mileage,
		OdometerUnitOfMeasure: 'miles',
		Color: exterior_color,
		InteriorColor: interior_color,
		InteriorType: interior_type,
		CrScore: cr_grade,
		CrUrl: createAbsoluteUrlFromPath(getConditionReportPageUrl(token, 'stockwave', disablePartnerScript)),
		VdpUrl: createAbsoluteUrlFromPath(
			buildURLForVehicleRedirection({
				vehicle,
				partner_name: 'stockwave',
				disable_partner_script: disablePartnerScript,
			}),
		),
		// Lane: undefined, //Not implemented in the BE yet
		// Run: undefined, //Not implemented in the BE yet
	};
	const stockWaveVehicleInformationJson = JSON.stringify(stockWaveVehicleInformationObject, null, 2);
	return stockWaveVehicleInformationJson;
};

export const getStockwaveScriptURL = () => {
	const DATE_NOW = new Date();
	const stockwaveScriptVersion = `${DATE_NOW.getMonth()}${DATE_NOW.getDate()}${DATE_NOW.getFullYear()}`;
	const stockwaveScriptUrl = `${publicEnvConfig.STOCKWAVE_SCRIPT_URL}?version=${stockwaveScriptVersion}`;
	return stockwaveScriptUrl;
};

export const STOCKWAVE_PAGES = ['/vehicle_detail/[vehicleid]', '/search', '/timed_sale'];
